import { Component, OnInit } from '@angular/core';
import { Preinscripcion } from '../models/Preinscripcion';
import { PreinscripcionesService } from '../servicios/preinscripciones.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  preinscripcion = {} as Preinscripcion;
  isBusy:boolean = true;
  constructor(private preinscripcionService:PreinscripcionesService,private router:Router,private spinner: NgxSpinnerService) { 
  }

  ngOnInit(): void {
    this.getPreinscripcionInfo();
  }

  getPreinscripcionInfo(): void {
    this.spinner.show();
    this.preinscripcionService.getUltimoPeriodo().subscribe(resp =>{
      this.preinscripcion = resp;
      this.spinner.hide();
      this.isBusy = false;
    });
  }

}
