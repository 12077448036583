export class GlobalConstant {

    //public static HOST_API = "https://plataforma.cbtis130.edu.mx";
	public static HOST_API = "https://plataforma.epujed.edu.mx";

    //DEV
    //public static HOST_API = "http://192.168.1.181:8000";

    //DEMO
    //public static HOST_API = "https://smartschooldemo.gmstore.com.mx";

    //prod
    //public static BUCKET_AWS = "https://cbtis130.s3.us-west-2.amazonaws.com";

    //public static NOMBRE_PLATAFORMA: string = "CBTis#130";
    //public static NOMBRE_ESCUELA: string = "Centro de Bachillerato Tecnologico Industrial y de Servicio #130";
    //public static LOGO_PLATAFORMA: string = "assets/cbtis130.svg";

    //DEMO
    //public static NOMBRE_PLATAFORMA: string = "CBTis#XXX";
    //public static NOMBRE_ESCUELA: string = "Centro de Bachillerato Tecnologico Industrial y de Servicio #XXX";
    //public static LOGO_PLATAFORMA: string = "assets/LogoGM.svg";

	public static NOMBRE_PLATAFORMA: string = "Preparatoria Diurna";
    public static NOMBRE_ESCUELA: string = "Escuela Preparatoria de la Universidad Juarez de Durango";
    public static LOGO_PLATAFORMA: string = "assets/diurna_logo.svg";
}