<app-menu></app-menu>

<div class="container-mensajes" *ngIf="!registro.registro">

    <img width="20%" src="assets/Editar.png">
    <h1><b>Actualización de Información</b><br>
        <div class="row">

            <div class="col-md-12">
                <h2></h2>
            </div>

            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>CURP :</mat-label>
                    <input matInput [(ngModel)]="reg.curp">
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Apellido Paterno :</mat-label>
                    <input matInput [(ngModel)]="reg.apellidoP">
                </mat-form-field>
            </div>

            <div class="col-md-12">
                <mat-form-field class="example-full-width">
                    <mat-label>Nombre(s) :</mat-label>
                    <input matInput [(ngModel)]="reg.nombre">
                </mat-form-field>
            </div>

        </div>

        <div class="row">

            <div class="col-md-12">
                <button mat-raised-button color="accent" class="botton_distance" (click)="ValidarUPD()">
                    <mat-icon>search</mat-icon> Buscar
                </button>
            </div>

        </div>
    </h1>
</div>

<app-registro [edicion]="true" [admin]="admin" *ngIf="registro.registro" [reg]="registro.registro"></app-registro>