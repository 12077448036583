import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActualizacionComponent } from './actualizacion/actualizacion.component';

//Components
import { DashboardComponent } from './dashboard/dashboard.component';
import { ImpresionComponent } from './impresion/impresion.component';
import { RegistroComponent } from './registro/registro.component';

const routes: Routes = [
  { path: "", component: DashboardComponent },
  { path: "solicitud", component: RegistroComponent },
  { path: "imprimir", component: ImpresionComponent },
  { path: "edicion", component: ActualizacionComponent },
  //Wild Card Route for 404 request
  { path: '**', pathMatch: 'full', component: DashboardComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash : true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
