<app-menu></app-menu>

<div class="container text-center">

    <h2>FELICIDADES, YA TIENES UNA SOLITUD PARA INICIAR CON EL PROCESO DE PREINSCRIPCION, POR FAVOR AHORA REVISA TODOS TUS DATOS UNA VEZ MAS SOBRE TODO LA FECHA DE NACIMIENTO, EL CURP Y NOMBRE. SI ES NECESARIO, PRESIONA EL SIGUIENTE BOTON PARA CORREGIR TUS DATOS</h2>

    <a routerLink="/edicion">
        <button mat-raised-button color="accent" class="botton_distance">
            <mat-icon>edit</mat-icon> Editar Registro
        </button>
    </a>
</div>

<iframe height="100%" width="100%" [src]="url" frameborder="0"></iframe>