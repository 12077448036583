<app-menu></app-menu>

<div [hidden]="isBusy">
    <div class="container-mensajes" *ngIf="!preinscripcion.success">

        <h1 *ngIf="preinscripcion.preinscripcion"><b>{{preinscripcion.preinscripcion.titulo}}</b></h1>
        <img width="200dp" height="200dp" src="assets/MisDatos.svg">
        <h1>{{preinscripcion.message}}<br>
            <div *ngIf="preinscripcion.preinscripcion">
                <h4>Si desea corregir datos de su solicitud presione el boton:</h4>
                <a routerLink="/edicion">
                    <button mat-raised-button color="accent">
                        <mat-icon>edit</mat-icon> Re-imprimir / Editar Registro
                    </button>
                </a>
            </div>
        </h1>
    </div>

    <div class="container-mensajes" *ngIf="preinscripcion.success && preinscripcion.elegibility">

        <div class="container">
            <h1><b>{{preinscripcion.preinscripcion.titulo}}</b></h1>
            <img width="300dp" src="assets/Registro.svg" alt="Registro Solicitud"/>
            <br>
            <a routerLink="/solicitud">
                <button mat-raised-button color="accent" class="botton_distance">
                    <mat-icon>add</mat-icon> Nueva Solicitud
                </button>
            </a>
            <br>
            <a routerLink="/edicion">
                <button mat-raised-button color="basic" class="botton_distance">
                    <mat-icon>edit</mat-icon> Re-imprimir / Editar Registro
                </button>
            </a>
        </div>

    </div>
</div>

<app-footer></app-footer>