import { Injectable } from '@angular/core';
import { HttpClient,HttpResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { GlobalConstant } from '../utils/GlobalConstant';
import { Preinscripcion } from '../models/Preinscripcion';
import { Registro,RegistroInfoResponse,RegistroResponse } from '../models/Registros';

@Injectable({
  providedIn: 'root'
})
export class PreinscripcionesService {

  HOST:any;

  constructor(private http: HttpClient) { 
    this.HOST = GlobalConstant.HOST_API;
  }

  getUltimoPeriodo():Observable<Preinscripcion> {
    return this.http.get<Preinscripcion>(this.HOST+"/api/preinscripciones/getUltimoPeriodo");
  }

  getUltimoPeriodoInfo():Observable<Preinscripcion> {
    return this.http.get<Preinscripcion>(this.HOST+"/api/preinscripciones/getUltimoPeriodoInfo");
  }

  postNuevaSolicitudPreinscripcion(data:Registro):Observable<RegistroResponse>{
    return this.http.post<RegistroResponse>(this.HOST+"/api/preinscripciones/RegistrarSolicitud",data);
  }

  postInfoPreinscripcion(data:object):Observable<RegistroInfoResponse>{
    return this.http.post<RegistroInfoResponse>(this.HOST+"/api/preinscripciones/InformacionAspirante",data);
  }

  postActualizarSolicitudPreinscripcion(data:Registro):Observable<RegistroResponse>{
    return this.http.post<RegistroResponse>(this.HOST+"/api/preinscripciones/ActualizarSolicitud",data);
  }


}
