import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute, ParamMap} from '@angular/router';
import { GlobalConstant } from '../utils/GlobalConstant';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-impresion',
  templateUrl: './impresion.component.html',
  styleUrls: ['./impresion.component.scss']
})
export class ImpresionComponent implements OnInit {

  url = {} as SafeUrl;

  constructor(private router: Router,private route: ActivatedRoute,private domSanitize:DomSanitizer ) { }

  ngOnInit(): void {
    this.url = this.domSanitize.bypassSecurityTrustResourceUrl(GlobalConstant.HOST_API+"/api/preinscripciones/Imprimir?id=" + this.route.snapshot.paramMap.get('id') + "&folio=" + this.route.snapshot.paramMap.get('folio'));
  }

}
